import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "./Changelog.scss"
const Changelog = () => {
  const data = useStaticQuery(graphql`
    query {
      changelogData: allPrismicChangelog {
        edges {
          node {
            id
            data {
              body {
                ... on PrismicChangelogDataBodyProductUpdate {
                  id
                  slice_label
                  slice_type
                  items {
                    update_details {
                      text
                      html
                      raw
                    }
                    update_image {
                      alt
                      url
                    }
                    update_tittle {
                      text
                      html
                      raw
                    }
                    update_type
                  }
                  primary {
                    release_version
                    update_date
                    update_header {
                      text
                      html
                      raw
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  console.log({ data })
  const formatAndDisplayChangelogs = () => {
    try {
      let changes = data.changelogData.edges[0].node.data.body

      return (
        <>
          {changes.map((change, index) => {
            return (
              <div className="changelog-container">
                <div className="changelog-release-info-container">
                  <p className="changelog-date tag is-warning">
                    {change.primary.update_date}
                  </p>
                  <p className="changelog-version tag is-warning">
                    v{change.primary.release_version}
                  </p>
                </div>

                <p className="changelog-header">
                  {change.primary.update_header.text}
                </p>
                {change.items.map((changeItem, index) => {
                  return (
                    <div className="changelog-item-container ">
                      <p className="changelog-item-type tag is-warning is-light">
                        {changeItem.update_type}
                      </p>{" "}
                      <p className="changelog-item-header">
                        {changeItem.update_tittle.text}
                      </p>
                      <p className="changelog-item-text">
                        {changeItem.update_details.text}
                      </p>
                      <img
                        className="changelog-item-image"
                        src={changeItem.update_image.url}
                        alt=""
                      />
                    </div>
                  )
                })}
              </div>
            )
          })}
        </>
      )
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div>
      <div className="columns">
        <div className="column is-3"></div>
        <div className="column is-6">
          <div className="changelog-page-container">
            <p className="changelog-page-header">
              Changelog<span style={{ color: "#fec600" }}>.</span>
            </p>
            <p className="changelog-page-subtext">
              Find out about our latest product changes as we continue improving
              to enable you to plan your next trip better.
            </p>
          </div>
          {formatAndDisplayChangelogs()}
        </div>
        <div className="column is-3"></div>
      </div>
    </div>
  )
}

export default Changelog
