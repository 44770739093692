import React, { Fragment } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Changelog from "../components/Changelogs/Changelog"
import ThankYou from "../components/ThankYou/ThankYou"

const ShoeChangelog = () => {
  return (
    <Fragment>
      <Changelog />
    </Fragment>
  )
}

export default () => (
  <Layout>
    <SEO pageTitle="Changelog" />
    <ShoeChangelog />
  </Layout>
)
